import React from "react"
import Container from "../utilities/wrapper"
import styled from "styled-components"
import { LightBlueTitle } from "../elements/titles"

const WhyUs = styled.section`
  padding: 4rem 0;

  .about-titles {
    text-align: center;
    padding-bottom: 3rem;
  }

  .choose-us-flex-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .choose-us-flex-wrapper p {
    padding: 0.25rem 0;
    width: 60%;
    color: #000000;
  }
`

const WhyChooseUsContent = () => {
  return (
    <WhyUs>
      <Container>
        <div className="choose-us-flex-wrapper">
          <LightBlueTitle className="about-titles">
            Why Choose Us?
          </LightBlueTitle>
          <p>
            We like to customize our services as per the needs of our clients.
            Be it chic and edgy or minimal and classy, our designs will bring
            the hues to life and will give a nice boost to your brand image and
            revenues. Our approach is professional and friendly at the same
            time.
          </p>
          <br />
          <p>
            The experience and passion of our team enables us to use the best of
            traditional and contemporary technologies and techniques to bring
            bright and fabulous designs to life.
          </p>
          <br />
          <p>
            Also, you need not spend a bomb on our services. We offer
            cost-effective and competitively priced design solutions that cater
            to your graphics needs without feeling heavy on your pockets.
          </p>
        </div>
      </Container>
    </WhyUs>
  )
}

export default WhyChooseUsContent
