import React from "react"
import Container from "../utilities/wrapper"
import styled from "styled-components"
import { LightGreenTitle } from "../elements/titles"

const WhoWeAre = styled.section`
  padding: 4rem 0;

  .about-titles {
    text-align: center;
    padding-bottom: 3rem;
  }

  .who-we-are-flex-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .who-we-are-flex-wrapper p {
    padding: 0.25rem 0;
    width: 60%;
    color: white;
  }
`

const WhoWeAreContent = () => {
  return (
    <WhoWeAre>
      <Container>
        <div className="who-we-are-flex-wrapper">
          <LightGreenTitle size="4rem" className="about-titles">
            Who We Are
          </LightGreenTitle>
          <p>
            Optima Graphix is owned and operated by Freddy Zamaro and his spouse
            Alejandra.
            <br /> They are an El Paso based company with over 17 years of
            experience in the graphics industry. Freddy is a disabled Army
            combat veteran who served in the military and got deployed to Iraq
            in 2003 in support of Operation Enduring Freedom and Operation Iraqi
            Freedom.
          </p>
          <br />
          <p>
            He is also a proud member of the Combat Vets Motorcycle Association
            (CVMA) where along with his brothers and sisters in arms, rides
            motorcycles and participates in events to raise money to help
            veterans in need
          </p>
        </div>
      </Container>
    </WhoWeAre>
  )
}

export default WhoWeAreContent
