import React from "react"
import Container from "../utilities/wrapper"
import styled from "styled-components"
import { LightBlueTitle } from "../elements/titles"

const WhyUs = styled.section`
  padding: 4rem 0;

  .about-titles {
    text-align: center;
    padding-bottom: 3rem;
  }

  .choose-us-flex-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .choose-us-flex-wrapper p {
    padding: 0.25rem 0;
    width: 60%;
    color: #000000;
  }
`

const OurGoalsContent = () => {
  return (
    <WhyUs>
      <Container>
        <div className="choose-us-flex-wrapper">
          <LightBlueTitle className="about-titles">Our Goals</LightBlueTitle>
          <p>
            We will work hard every day to set the standard of quality in our
            industry. With Integrity and great customer service, we will strive
            to fulfill our customer’s needs and exceed their expectations. With
            a combination of quality materials, innovative production methods
            and creative thinking, we will ensure our customers’ satisfaction.
          </p>
        </div>
      </Container>
    </WhyUs>
  )
}

export default OurGoalsContent
